// n=5 2017
// nis5 award pages 2015
// BNLA
// merceses perfecte service
// KPN On Bike No Phone
// Brengflex driver app
const projects = [
    {
        "id": "nis5",
        "title": "N=5 website",
        "img": "/assets/projects/park-line/header.jpg",
        "colors": "#eeeeee",
        "tags": "frontend, web (react)",
        "clients": "N=5",
        "content": "<p><b>Website voor N=5</b></p><br><img class='auto' src='/assets/projects/nis5/screenshot-nis5.nl-2019.01.22-13-21-09.jpg'>",
        "links": [
            {
                "title": "nis5.nl/",
                "url": "https://nis5.nl",
                "fa_icon": "fa fa-laptop"
            }
        ]
    },
    {
        "id": "park-line",
        "title": "Park-line",
        "img": "/assets/projects/park-line/header.jpg",
        "colors": "#0099ce",
        "tags": "web (wordpress)",
        "clients": "Parkmobile",
        "content": "<p><b>Websites voor park-line</b></p><br><img class='auto' src='/assets/projects/park-line/home.jpg'>",
        "links": [
            {
                "title": "park-line.nl/",
                "url": "https://park-line.nl",
                "fa_icon": "fa fa-laptop"
            }
        ]
    },
    {
        "id": "parkmobile",
        "title": "Parkmobile",
        "img": "/assets/projects/parkmobile/header.jpg",
        "colors": "#92d400",
        "tags": "web (wordpress)",
        "clients": "Parkmobile",
        "content": "<p><b>Websites voor parkmobile</b></p><br><img class='auto' src='/assets/projects/parkmobile/home.jpg'>",
        "links": [
            {
                "title": "parkmobile.nl/",
                "url": "https://parkmobile.nl",
                "fa_icon": "fa fa-laptop"
            }
        ]
    },
    {
        "id": "de-grote-prijs-van-nederland",
        "title": "De Grote Prijs van Nederland",
        "img": "/assets/projects/003.jpg",
        "colors": "#F32F9D",
        "tags": "web (wordpress), design by Keyhé",
        "clients": "De Grote Prijs van Nederland",
        "content": "<p><b>The Grote Prijs van Nederland is a series of music awards handed out yearly since 1983 to recognize talented new Dutch musicians, both solo artists and bands.</b> It is the largest and longest-running pop music competition in the Netherlands. Previous winners include producer Junkie XL, rock band Green Lizard, rapper Brainpower, pop group Volumia! and singer-songwriter Signe Tollefsen.</p><br><img class='auto' src='/assets/projects/de-grote-prijs-van-nederland/screenshot-groteprijsvan.nl 2017-09-20 15-30-04-734.jpeg'>",
        "links": [
            {
                "title": "groteprijsvan.nl",
                "url": "https://groteprijsvan.nl/",
                "fa_icon": "fa fa-laptop"
            }
        ]
    },
    {
        "id": "roger-bacon",
        "title": "Roger Bacon Eyewear",
        "img": "/assets/projects/roger-bacon/header.png",
        "colors": "rgba(201, 94, 92, 1)",
        "tags": "web (wordpress)",
        "clients": "Codeandcoding, Roger Bacon",
        "content": "<p><b>Website voor Roger Bacon eyewear.</b><br>Roger Bacon is een brillen producent.</p><br><img class='half'  src='/assets/projects/roger-bacon/screenshot-rogerbacon-eyewear.com 2017-09-20 14-50-29-380.jpg'><img class='half' src='/assets/projects/roger-bacon/glasses.jpg'>",
        "contrast": "less",
        "links": [
            {
                "title": "rogerbacon-eyewear.com",
                "url": "https://rogerbacon-eyewear.com/",
                "fa_icon": "fa fa-laptop"
            }
        ]
    },
    {
        "id": "cumulus",
        "title": "Cumulus | Digitale lesmethode",
        "img": "/assets/projects/cumulus/header.jpg",
        "colors": "rgba(87, 216, 178, 1)",
        "tags": "web (laravel)",
        "clients": "Own startup with Thijs and Henk",
        "content": "<p><b>Cumulus is een digitale lesmethode economie voor het voortgezet onderwijs (havo en vwo).</b><br>Cumulus is een volledige lesmethode in de vorm van visueel aantrekkelijke presentaties en aanvullingen. De lessen en opgaven kunnen onafhankelijk gebruikt worden, maar ook als waardevolle uitbreiding op alle bestaande methodes.</p><br><img class=\"half\" src=\"/assets/projects/cumulus/home.jpg\"><img class=\"half\"  src=\"/assets/projects/cumulus/les.jpg\">",
        "contrast": "less",
        "links": [
            {
                "title": "Cumulus.co",
                "url": "https://cumulus.co",
                "fa_icon": "fa fa-laptop"
            }
        ]
    },
    {
        "id": "maakhetzeniettemakkelijk",
        "title": "maakhetzeniettemakkelijk.nl",
        "img": "/assets/projects/maakhetzeniettemakkelijk/header.jpg",
        "colors": "#ffff00",
        "tags": "web (js, wordpress met custom routing en templating, sprite animations)",
        "clients": "Rijksoverheid, N=5",
        "content": "<p><b>De campagne ‘Maak het ze niet te makkelijk’ is een initiatief van het ministerie van Veiligheid en Justitie en haar campagnepartners en geeft informatie en tips om de eigen veiligheid te vergroten, zoals het voorkomen van woninginbraak.</b> Door wettelijk verplichte uitbestedingsregels waar de overheid mee te maken heeft is het in 2015 overgenomen van N=5 en WOEK waardoor andere onderdelen dan het initiele 'woninginbraak' later door een andere (mindere ☺) partij is gedaan.</p><br><img class='auto' src='/assets/projects/maakhetzeniettemakkelijk/home.jpg'/>",
        "contrast": "",
        "links": [
            {
                "title": "Website",
                "url": "http://www.maakhetzeniettemakkelijk.nl/woninginbraak",
                "fa_icon": "fa fa-laptop"
            }
        ]
    },
    {
        "id": "mercedes-deperfecteservice",
        "title": "Mercedes | De perfecte service",
        "img": "/assets/projects/mercedes-deperfecteservice/header.jpg",
        "colors": "#ffde08;#df348a",
        "tags": "Web, custom js, mad responsive",
        "clients": "Mercedes, N=5",
        "content": "<p><b>Volledig responsive geanimeerde website voor Mercedes.</b></p><p>Design en motionbrief van Marcel van Berghuis (N=5)</p><br><br><img class='halfx' src='/assets/projects/mercedes-deperfecteservice/001.jpg'><img class='halfx' src='/assets/projects/mercedes-deperfecteservice/002.jpg'><img class='halfx' src='/assets/projects/mercedes-deperfecteservice/003.jpg'><div class=\"owl-carousel app\"><img class='half' src='/assets/projects/mercedes-deperfecteservice/mobile001.jpg'><img class='half' src='/assets/projects/mercedes-deperfecteservice/mobile002.jpg'><img class='half' src='/assets/projects/mercedes-deperfecteservice/mobile003.jpg'></div>",
        "contrast": "less",
        "links": [
            {
                "title": "Website",
                "url": "http://deperfecteservice.nl/perfect/",
                "fa_icon": "fa fa-laptop"
            }
        ]
    },
    {
        "id": "fomu-shooting-range",
        "title": "Shooting Range",
        "img": "/assets/projects/002.jpg",
        "colors": "#99c20e",
        "tags": "iOS, Android, iBeacons",
        "clients": "FoMu Antwerpen, in10",
        "content": "<p><strong>For the Antwerp based photomuseum FoMo we developed an iOS and Android App.</strong> The apps beacons, imagerecognition, gyroscope panaroma viewer with hotspots</p><br><img src=\"/assets/projects/fomu-shooting-range/001.jpg\"><img src=\"/assets/projects/fomu-shooting-range/002.jpg\"><img src=\"/assets/projects/fomu-shooting-range/003.jpg\"><img src=\"/assets/projects/fomu-shooting-range/004.jpg\"><img src=\"/assets/projects/fomu-shooting-range/005.jpg\">",
        "links": [
            {
                "title": "App Store",
                "url": "https://itunes.apple.com/be/app/shooting-range-fotografie/id882735326?ls=1&mt=8",
                "fa_icon": "fa fa-apple"
            },
            {
                "title": "Play Store",
                "url": "https://play.google.com/store/apps/details?id=be.fomu.shootingrange",
                "fa_icon": "fa fa-android"
            }
        ]
    },
    {
        "id": "alleenjijbepaalt",
        "title": "alleenjijbepaalt.nl",
        "img": "/assets/projects/alleenjijbepaalt/header.jpg",
        "colors": "#001d44",
        "tags": "web",
        "clients": "Rijksoverheid, N=5",
        "content": "<p><b>Alleen jij bepaalt wie je bent is een door het ministerie van Veiligheid en Justitie ontwikkelde interventie waarbij kwetsbare, schoolgaande jongeren een zinvolle vrijetijdsbesteding krijgen dankzij de inzet van een teamsport.</b><br>Het project wordt ondersteund door zes bekende sporters: voetballers Ricardo van Rhijn, Soufiane Touzani en Roxanne Hehakaija, honkballer Raily Legito basketballer Chip Jones en scheidsrechter Serdar Gözübüyük . </p><br><img class='auto' src='/assets/projects/alleenjijbepaalt/home.jpg'/>",
        "contrast": "",
        "links": [
            {
                "title": "Website",
                "url": "https://www.alleenjijbepaalt.nl/",
                "fa_icon": "fa fa-laptop"
            }
        ]
    },
    {
        "id": "tinytale",
        "title": "Tiny Tale",
        "img": "/assets/projects/004.jpg",
        "colors": "#bbd3b0;#fc5e31",
        "tags": "iOS, android, web",
        "clients": "Tiny Tale",
        "content": "<h4>Description</h4><p>Tiny Tale is the private way to save your kids moments (e.g. photo’s, videos, quotes and stories) and to instantly share these with people YOU invite! Photos and comments by family and friends make it a safe, private and dynamic photo diary to share. The perfect way to experience the story of your children’s lives together.</p><div class=\"owl-carousel app\"><br><img src=\"/assets/projects/tinytale/001.jpg\"><img src=\"/assets/projects/tinytale/002.jpg\"><img src=\"/assets/projects/tinytale/003.jpg\"><img src=\"/assets/projects/tinytale/004.jpg\"><img src=\"/assets/projects/tinytale/005.jpg\"></div>",
        "links": [
            {
                "title": "App Store",
                "url": "https://itunes.apple.com/nl/app/tiny-tale/id660652937?mt=8",
                "fa_icon": "fa fa-apple"
            },
            {
                "title": "Play Store",
                "url": "https://play.google.com/store/apps/details?id=com.tinytale.android",
                "fa_icon": "fa fa-android"
            },
            {
                "title": "Website",
                "url": "http://www.tinytale.com/nl/",
                "fa_icon": "fa fa-laptop"
            }
        ]
    },
    {
        "id": "nl-alert",
        "title": "NL Alert",
        "img": "/assets/projects/008.jpg",
        "colors": "#ffff00",
        "tags": "web (js, wordpress, svg animations)",
        "clients": "Rijksoverheid, N=5",
        "content": "<p><b>NL-Alert is een alarmmiddel van de overheid voor op de mobiele telefoon.</b> Met NL-Alert kan de overheid mensen in de directe omgeving van een noodsituatie met een tekstbericht informeren.</p><br><img class='half' src='/assets/projects/nl-alert/nl-alert-01.jpg'/><img class='half' src='/assets/projects/nl-alert/nl-alert-02.jpg'/>",
        "contrast": "less",
        "links": [
            {
                "title": "Website",
                "url": "http://www.nl-alert.com/",
                "fa_icon": "fa fa-laptop"
            }
        ]
    },
    {
        "id": "artrocks",
        "title": "Artrocks",
        "img": "/assets/projects/006.jpg",
        "colors": "#f04953",
        "tags": "website (wordpress)",
        "clients": "Boijmans van Beuningen, Groninger Museum, Centraal Museum Utrecht",
        "content": "<p>Museum Boijmans Van Beuningen, Groninger Museum en Centraal Museum Utrecht nodigen muzikanten uit om zich te laten inspireren door iconen uit hun museumcollecties.</p><br><img class='auto' src='/assets/projects/artrocks/art-rocks01.jpg'/>",
        "links": [
            {
                "title": "Go to Artrocks.nl",
                "url": "http://www.artrocks.nl",
                "fa_icon": "fa fa-laptop"
            }
        ]
    },
    {
        "id": "telefoontjevansint",
        "title": "Telfoontje van Sint",
        "img": "/assets/projects/007.jpg",
        "colors": "#363593",
        "tags": "web (bootstrap, laravel)",
        "clients": "Telfort, N=5",
        "content": "<p>Via telefoontjevansint.nl kunnen ouders hun kinderen aanmelden voor een Telefoontje van Sint. De informatie die ze hiervoor moesten achterlaten: een telefoonnummer, de plek waar de cadeautjes verstopt liggen en wat persoonlijke informatie over de kinderen, zoals leeftijd en hobby’s.</p><br><img src='/assets/projects/telefoontjevansint/001.jpg'/><img src='/assets/projects/telefoontjevansint/002.jpg'/><img src='/assets/projects/telefoontjevansint/003.jpg'/><div style=\"padding:56.25% 0 0 0;position:relative;\"><iframe src=\"https://player.vimeo.com/video/82943533?title=0&byline=0&portrait=0\" style=\"position:absolute;top:0;left:0;width:100%;height:100%;\" frameborder=\"0\" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div><script src=\"https://player.vimeo.com/api/player.js\"></script>",
        "contrast": "less",
        "links": [],
    },
];


export default projects;

