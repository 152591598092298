export function random(min, max) {
    return Math.random() * (max - min) + min;
}

export function normalize(value, min, max) {
    return (value - min) / (max - min);
}

export function constrain(val, min, max) {
    return val > max ? max : val < min ? min : val;
}
