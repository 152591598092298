import Vector from "victor";
import {constrain} from './helpers';


class ElementRepeller {
    constructor(element, power) {
        this.power = 100000;
        this.element = element;
    }

    calculateRepelForce(p) {
        const boundingBox = this.element.getBoundingClientRect();
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;


        if(!this.inBoundingBox(boundingBox,p.position, scrollTop)){
            return new Vector(0, 0);
        }



        const middleX = boundingBox.left + boundingBox.width/2;
        const middleY = boundingBox.top + boundingBox.height/2 + scrollTop;

        const middle = new Vector(middleX, middleY);

        var direction = middle.clone().subtract(p.position);

        var force  = -0.01 * direction.length();

        // calculate forceVector
        direction.normalize();
        direction.multiply(new Vector(force, force));


        return direction;
    }

    inBoundingBox(boundingBox, position, scrollTop){
        const padding = 10;
        return position.x > boundingBox.left - padding &&
            position.x < boundingBox.right + padding &&
            position.y > boundingBox.top - padding + scrollTop &&
            position.y < boundingBox.bottom + padding + scrollTop;
    }
}

export default ElementRepeller;
