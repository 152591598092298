import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import '../sass/app.scss'
import Particles from "./particlesSVG";


class Layout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
        }

        // smooth mousewheel (DOMMouseScroll)
        // window.addEventListener('mousewheel', function(e) {
        //     var dir,
        //         amt = 100;
        //
        //     e.preventDefault();
        //     console.log(e);
        //     if(e.type === 'mousewheel') {
        //         dir = e.wheelDelta > 0 ? '-=' : '+=';
        //     }
        //     else {
        //         dir = e.detail < 0 ? '-=' : '+=';
        //     }
        //
        //     console.log(dir+amt);
        //
        //     // $('html, body').stop().animate({
        //     //     scrollTop: dir + amt
        //     // },500, 'linear');
        // })
    }

    componentDidMount() {
        setTimeout(()=>{
            this.setState({loaded: true});
        },500);
    }

    render(){
        const { loaded } = this.state;
        const { children } = this.props;

        return <StaticQuery
            query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
            render={data => (
                <div className={loaded ? "layout-loaded" : "layout-not-loaded"}>
                    <Particles />
                    {children}
                </div>
            )}
        />
    }
}

// const Layout = ({ children }) => (
//   <StaticQuery
//     query={graphql`
//       query SiteTitleQuery {
//         site {
//           siteMetadata {
//             title
//           }
//         }
//       }
//     `}
//     render={data => (
//         <div>
//             <Particles />
//             {children}
//         </div>
//     )}
//   />
// )

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
