import Particle from './particle';
import { normalize } from  './helpers';

import ElementRepeller from "./elementRepeller";


class ParticleSystem {
    constructor() {
        this.particles = [];
        this.elementRepellers = [];

        this.mouseX = null;
        this.mousePosition = {x: 999999, y: 9999999};
        this.mousePositionPage = {x: 999999, y: 9999999};
        this.mouseY = null;

        this.focusWord = "heart";
    }

    updateMouse(mouseX, mouseY, mousePosition, mousePositionPage) {
        this.mouseX = mouseX;
        this.mouseY = mouseY;
        this.mousePosition = mousePosition;
        this.mousePositionPage = mousePositionPage;
    }

    run() {
        // this.angle += 0.1;
        var W = window.innerWidth;
        var H = window.innerHeight;


        var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = 0;//window.pageYOffset || document.documentElement.scrollTop;


        for (var i = 0; i < this.particles.length; i++) {

            var p = this.particles[i];

            //Updating X and Y coordinates
            //We will add 1 to the cos function to prevent negative values which will lead flakes to move upwards
            //Every particle has its own density which can be used to make the downward movement different for each flake
            //Lets make it more random by adding in the radius
            //p.y += Math.cos(this.angle + p.d) + 0 + p.r / 2;
            // p.x += Math.sin(this.angle) * 2;


            // p.x += (this.mouseX * -0.02);
            // p.y += (this.mouseY * 0.02);

            const distance = (Math.abs(p.position.x - this.mousePositionPage.x) + Math.abs(p.position.y - this.mousePositionPage.y)) * 0.5;

            //(distance - 1) / (5 - min)


            // console.log(distance);
            // p.scale = distance > 50 ? 1 : 2 - (distance / 50);
            const maxScale = 2;
            const maxDistance = 50;


            p.scale = distance > maxDistance ? 1 : maxScale - ((maxScale - 1) * normalize(distance, 0, maxDistance));


            // console.log(W);
            if (p.position.x > W + 50) {
                p.position.x = 0 - 40;
            } else if (p.position.x < 0 - 50) {
                p.position.x = W + 40;
            }
            //
            if (p.position.y > H + 50 + scrollTop) {
                p.position.y = p.position.y - H - 100;
            } else if (p.position.y < scrollTop - 50) {
                p.position.y = p.position.y + H + 100;
            }

            // p.focus = this.focusWord == p.tag;

            p.run(this.mouseX, this.mouseY, this.particles);


        }
    }

    addParticle(position, element, tag) {
        this.particles.push(new Particle(position, element, tag));
    }

    applyForce(f) {
        for (var i = 0; i < this.particles.length; i++) {
            this.particles[i].applyForce(f);
        }
    }

    applyRepeller(r) {
        for (var i = 0; i < this.particles.length; i++) {
            var p = this.particles[i];
            var force = r.calculateRepelForce(p);
            p.applyForce(force);
        }
    };

    addElementRepeller(id) {
        var element = document.getElementById(id);

        const elementRepeller = new ElementRepeller(element, 1000);

        this.elementRepellers.push(elementRepeller);
    }

    applyElementRepellers() {
        for (var i = 0; i < this.elementRepellers.length; i++) {
            var er = this.elementRepellers[i];

            this.applyRepeller(er);
        }
    }
}

export default  ParticleSystem;
