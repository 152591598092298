import React from 'react'

import projects from "../data/projects";
import {Link} from "gatsby";
import PropTypes from "prop-types";

class Projects extends React.Component {
    _handleMouseEnter(color){
        // if (typeof window !== `undefined`) {
        //     window.settings.dust_fillStyle = color;
        //     window.settings.strokeStyle = color;
        // }
    }

    render() {
        const {excludedIds, fromHome} = this.props;

        return  <div className="page" id="work">

            <h1>Projects</h1>
            <ul className="projects">
                {projects.map((project, index) => {
                    if( excludedIds.includes(project.id) ){
                        return false;
                    }
                    return <li key={ project.id }  className="project" dust-color={project.colors} onMouseEnter={() => { this._handleMouseEnter(project.colors)}}>
                        <div className="header">
                            {/* <Link to={`/projects/${project.id}`} state={{ fromHome }}> */}
                                <img className={project.contrast ==='less' ? "less_contrast" : "" }
                                 src={`/assets/projects/${project.id}/header.jpg`} alt=""/>
                            {/* </Link> */}
                        </div>
                    </li>;
                })}
            </ul>
        </div>;
    }
}

Projects.propTypes = {
    excludedIds: PropTypes.array,
    fromHome: PropTypes.bool,
}

Projects.defaultProps = {
    excludedIds: [],
    fromHome: false,
}

export default Projects
