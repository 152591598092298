import Vector from "victor";

import {random} from  './helpers';

class Particle {
    constructor(position, element, tag) {
        this.position = position;
        this.element = element;
        this.tag = tag;
        this.orginalTag = tag;

        this.focus = false;

        this.friction = new Vector(0.90, 0.90);
        this.acceleration = new Vector(0, 0);

        const maxIntrinsic = 0.2;

        this.velocityIntrinsic = new Vector(random(-maxIntrinsic, maxIntrinsic), random(-maxIntrinsic, maxIntrinsic));
        this.velocity = new Vector(0, 0);

        this.timeToLive = 255.0;
        this.mass = 10;
        this.scale = 1;
        this.radius = Math.random() * 1 + 1;
    }

    run(mouseX, mouseY, particles) {
        this.update(particles);
        this.display(mouseX, mouseY);
    }

    update(particles) {                
        if (window.settings.forceIcon && this.tag != window.settings.forceIcon){
            this.tag = window.settings.forceIcon;
            this.element.src = '/assets/icons/' + this.tag  + '.svg';
        } else if (!window.settings.forceIcon && this.tag != this.orginalTag ){
            this.tag = this.orginalTag;
            this.element.src = '/assets/icons/' + this.tag + '.svg';
        }

        this.velocity.multiply(this.friction);

        this.applySeparation(particles);

        // if(this.velocity.length() < 0.5) {
        //     this.velocity.add(this.velocityIntrinsic);
        // }

        this.velocity.add(this.acceleration);

        this.velocity.limit(10,0.5);

        this.position.add(this.velocityIntrinsic);
        this.position.add(this.velocity);
        this.acceleration.multiply(new Vector(0, 0));
        // this.timeToLive -= 2.0;
    }

    // Separation
    applySeparation(boids) {
        const desiredseparation = 100;
        let steer = new Vector(0, 0);
        let count = 0;
        // For every boid in the system, check if it's too close
        for (var i = 0; i < boids.length; i++) {
            const other = boids[i];
            let d = this.position.distance(other.position);


            // If the distance is greater than 0 and less than an arbitrary amount (0 when you are yourself)
            if ((d > 0) && (d < desiredseparation)) {
                // Calculate vector pointing away from neighbor
                let diff = this.position.clone().subtract(other.position);

                diff.normalize();

                diff.divide(new Vector(d,d));        // Weight by distance

                steer.add(diff);
                count++;            // Keep track of how many
            }
        }



        // // Average -- divide by how many
        // if (count > 0) {
        //     steer.divide(new Vector(count,count));
        // }



        // As long as the vector is greater than 0
        if (steer.length() > 0) {
            // First two lines of code below could be condensed with new PVector setMag() method
            // Not using this method until Processing.js catches up
            // steer.setMag(maxspeed);

            // Implement Reynolds: Steering = Desired - Velocity
            steer.normalize();
            // steer.multiply(maxspeed);
            // steer.subtract(velocity);
            // steer.limit(maxforce);
        }

        this.applyForce(steer);
    }

    applyForce(force) {
        force.divide(new Vector(this.mass, this.mass));
        this.acceleration.add(force);
    }

    display(mouseX, mouseY) {
        // noStroke();
        // fill(204, 241, 255, 100);
        // ellipse(this.position.x, this.position.y, 12, 12);
        if(this.focus){
            this.scale = 2;
            this.element.style.filter = "saturate(100) hue-rotate(-24deg)";
        }

        if(this.element.woekNaturalWidth){
            const resp = window.innerWidth > 768 ? 1 : 0.5;
            this.element.style.width = this.scale * this.element.woekNaturalWidth * resp + "px";
        }


        const offset = -0.3;//-1.02;
        let offsetX = 0;
        let offsetY = 0;

        if(window.parallaxJs){
            offsetX = window.parallaxJs.velocityX;
            offsetY = window.parallaxJs.velocityY;
        }

        const left = (this.position.x + offsetX * offset) - (this.element.offsetWidth / 2);
        const top = (this.position.y + offsetY * offset) - (this.element.offsetHeight / 2);

        this.element.style.transform = `translate(${left}px,${top}px)`;

        // this.element.style.left = (this.position.x + offsetX * offset) - (this.element.offsetWidth / 2) + "px";
        // this.element.style.top = (this.position.y + offsetY * offset) - (this.element.offsetHeight / 2) + "px";
    }
}

export default  Particle;
