import Vector from "victor";
import {constrain} from './helpers';


class Repeller {
    constructor(x, y) {
        this.power = 30000;
        this.position = new Vector(x, y);
    }

    calculateRepelForce(p) {
        var direction = this.position.clone().subtract(p.position);

        var distance = direction.length();
        if (distance > 300) {
            return new Vector(0, 0);
        }

        distance = constrain(distance, 1, 300);
        var force = -1 * this.power / (distance * distance);

        // calculate forceVector
        direction.normalize();
        direction.multiply(new Vector(force, force));

        return direction;
    };
}

export default Repeller;
