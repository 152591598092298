import PropTypes from 'prop-types'
import React from 'react'

import Vector from 'victor';

import { ParticleSystem, Repeller } from './ParticlesSystem';

class Particles extends React.Component {
    constructor(props) {
        super(props);
        // create a ref to store the textInput DOM element
        this.canvas = React.createRef();

        this.icons = [
            'alarm',
            'arrow',
            'atom',
            'bone',
            'brain',
            'cap',
            'car',
            'cb750',
            'croisant',
            'dog-pixel',
            'dog',
            'drum',
            'eye',
            'flame',
            'fries',
            'hand-pixel',
            'heart',
            'hula',
            'illuminati',
            'keyboard',
            'paperplane',
            'plant',
            'popsicle',
            'skull',
            'sub',
            'trash',
            'whale',
        ];

        this.angle = 0;
        this.ctx = null;
        this.mp = this.icons.length;
        this.particles = [];
        this.particleSystem = new ParticleSystem();

        this.mouseX = null;
        this.mousePosition = {x: 999999, y: 9999999};
        this.mousePositionPage = {x: 999999, y: 9999999};
        this.mouseY = null;

        this.update = this.update.bind(this);

        if (typeof window !== `undefined`) {
            window.settings = {};
            window.settings.dustOn = true;
            window.settings.dust_fillStyle = "rgba(0, 0, 0, 1)";
            window.settings.strokeStyle = "rgba(255, 255, 255, 0.8)";
            window.settings.forceIcon = null;
        }
    }

    componentDidMount() {
        this.initParticles();
        setInterval(() => {
            window.requestAnimationFrame(this.update.bind(this));
        }, 33);


        document.addEventListener("mousemove", this._handleMousePosition.bind(this));
    }

    _handleMousePosition(event) {
        var percentageFromMiddle = event.x / window.innerWidth - 0.5;
        var percentageFromMiddleTop = event.y / window.innerHeight - 0.5;

        percentageFromMiddle *= 100;
        percentageFromMiddleTop *= 100;

        this.mouseX = percentageFromMiddle;
        this.mouseY = percentageFromMiddleTop;

        var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        this.mousePosition = {x: event.x, y: event.y};
        this.mousePositionPage = {x: event.x + scrollLeft, y: event.y + scrollTop};
    }

    initParticles() {
        //canvas dimensions
        var W = window.innerWidth;
        var H = window.innerHeight;

        var numberOfParticles = 15;

        function shuffleArray(a) {
            for (let i = a.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [a[i], a[j]] = [a[j], a[i]];
            }
            return a;
        }

        shuffleArray(this.icons);

        //snowflake particles
        for (var i = 0; i < numberOfParticles; i++) {
            var img = document.createElement("img");
            img.className = "iconParticle";
            img.style.position = "absolute";
            img.style.zIndex = 3;
            img.style.top = 0;
            img.style.left = 0;
            // img.style.width = "50px";
            img.style.display = "block";

            img.woekNaturalWidth = 0;

            img.onload = function(e){
                e.target.woekNaturalWidth = e.target.naturalWidth;
                e.target.woekNaturalHeight = e.target.naturalHeight;
            };


            img.src = '/assets/icons/' + this.icons[i] + '.svg';
            var wrapper = document.getElementById("icons");
            wrapper.appendChild(img);


            this.particleSystem.addParticle(new Vector(Math.random() * W, Math.random() * H), img, this.icons[i]);
        }

        // this.particleSystem.addElementRepeller('elementRepeller01');
    }

    update() {
        //needed for scale and push
        this.particleSystem.updateMouse(this.mouseX, this.mouseY, this.mousePosition, this.mousePositionPage);

        this.particleSystem.applyElementRepellers();

        // mousepointer repeller
        const repeller = new Repeller(this.mousePosition.x, this.mousePosition.y);
        this.particleSystem.applyRepeller(repeller);

        this.particleSystem.run();
    }

    render() {
        return <canvas ref={(node) => {
            this.canvas = node
        }}
                       style={{
                           display: "block",
                           width: "100%",
                           height: "100%",
                           position: "fixed",
                           pointerEvents: "none",
                           zIndex: "3",
                       }}
        />;
    }
}

Particles.propTypes = {
    fillColor: PropTypes.string,
    strokeColor: PropTypes.string,
}

Particles.defaultProps = {
    fillColor: "rgba(0, 0, 0, 1)",
    strokeColor: "rgba(255, 255, 255, 0.8)",
}

export default Particles
